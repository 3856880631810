import { Box, Chip, Divider, Typography } from "@mui/material";
import React from "react";
import ForgotPasswordForm from "./ChangePasswordForm";

type Props = {};

const ForgotPassword = (props: Props) => {
  return (
    <Box>
      {/* <Box mb={4}>
        <Typography variant="h5" align="center">
          เปลี่ยนรหัสผ่าน
        </Typography>
      </Box> */}
      <Box mt={2}>
        <ForgotPasswordForm />
      </Box>
    </Box>
  );
};

export default ForgotPassword;
