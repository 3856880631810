import React from "react";
import BannerCarousel from "../components/carousel/BannerCarousel";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import Dashboard from "./Dashboard";
import DepositAndWithdrawButtonGroup from "./DepositAndWithdrawButtonGroup";
import InformationGroup from "./InformationGroup";
import { Box } from "@mui/system";

type Props = {};

const Content = (props: Props) => {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  return (
    <>
      <Box sx={{ pt: 1 }}>
        <DepositAndWithdrawButtonGroup />
      </Box>
      {!isLoggedIn && <BannerCarousel />}
      {isLoggedIn && <Dashboard />}
      <Box sx={{ mt: 3 }}>
        <InformationGroup />
      </Box>
    </>
  );
};

export default Content;
