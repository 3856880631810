import * as React from "react";
import { Box, Typography } from "@mui/material";
import SignUpStepper from "@/sections/register/Stepper";
import StepperContent from "@/sections/register/StepperContent";

export default function SignUp() {
  return (
    <Box>
      {/* <Box mb={0}>
        <Typography variant="h5" align="center">
          สมัครสมาชิก
        </Typography>
      </Box> */}
      {/* <SignUpStepper /> */}
      <Box>
        <StepperContent />
      </Box>
    </Box>
  );
}
