import * as React from "react";
import { Box, Typography } from "@mui/material";
import ForgotPasswordStepper from "./Stepper";
import StepperContent from "./StepperContent";

export default function ForgotPassword() {
  return (
    <Box>
      {/* <Box mb={0}>
        <Typography variant="h5" align="center">
          ลืมรหัสผ่าน
        </Typography>
      </Box> */}
      {/* <ForgotPasswordStepper /> */}
      <Box>
        <StepperContent />
      </Box>
    </Box>
  );
}
