"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Typography,
  Stack,
  styled,
  Chip,
  Button,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import useHistoryList from "@/hooks/fetchers/useHistoryList";
import numeral from "numeral";
import moment from "moment";
import { Icon } from "@iconify/react";

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: theme.palette.background.default,
  padding: theme.spacing(2),
  fontSize: 14,
  textAlign: "center",
  borderRadius: theme.spacing(2),
}));

const CreatedAt = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  textAlign: "left",
}));

const Status = styled(Chip)(({ theme }) => ({
  fontSize: 12,
  textAlign: "center",
}));

export default function HistoryForm() {
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1); // Reset to first page when tab changes
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: any
  ) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setPage(1); // Reset to first page when rowsPerPage changes
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
      </Box>
    );
  }

  const parseStatus = (status: any) => {
    switch (status) {
      case "confirmed":
      case "success":
        return { color: "#3DFB35", value: "สำเร็จ" };
      case "newrecord":
        return { color: "#f9ad48", value: "รอทำรายการ" };
      case "cancel":
        return { color: "#f13939", value: "ยกเลิก" };
      default:
        return { color: "orange.300", value: "ไม่พบสถานะ" };
    }
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChangeTab =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data: histories } = useHistoryList();

  const calculateTotal = (items: any[]) => {
    return items.reduce((acc, item) => acc + item.amount, 0);
  };

  const totalDeposits = histories?.total_deposit
    ? histories.total_deposit
    : 0;
  const totalWithdraws = histories?.total_withdraw
    ? histories.total_withdraw
    : 0;

  const totalWithdrawsSuccess = histories?.withdraws
    ? histories.withdraws
      .filter((item: any) => item.status === "success")
      .reduce((acc: number, item: any) => acc + parseFloat(item.amount), 0)
    : 0;

  const paginatedDeposits = histories?.deposits?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );
  const paginatedWithdraws = histories?.withdraws?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <Box pt={2} sx={{ width: "100%" }}>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            ยอดฝากรวม:
          </Typography>
          <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
            fontSize: 18,
            fontWeight: 700,
          }}>
            {numeral(totalDeposits).format("0,0.00")}
          </Typography>

          {/* <Button fullWidth variant="contained" color="primary">
            ยอดฝากรวม: {numeral(totalDeposits).format("0,0.00")} ฿
          </Button> */}
        </Box>

      </Stack>

      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            ยอดถอนรวม:
          </Typography>
          <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
            fontSize: 18,
            fontWeight: 700,
          }}>
            {numeral(totalWithdrawsSuccess).format("0,0.00")}
          </Typography>

          {/* <Button fullWidth variant="contained" color="primary">
            ยอดถอนรวม: {numeral(totalWithdrawsSuccess).format("0,0.00")} ฿
          </Button> */}
        </Box>
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          variant="fullWidth"
        >
          <Tab label="ฝาก" sx={{ color: (theme) => theme.gradient[900] }} />
          <Tab label="ถอน" sx={{ color: (theme) => theme.gradient[900] }} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        {paginatedDeposits?.length > 0 ? (
          <>
            {paginatedDeposits?.map((item: any, key: any) => {
              const status = parseStatus("confirmed");
              return (
                <Accordion
                  key={key}
                  sx={{ width: "100%" }}
                  expanded={expanded === `${item?.id}`}
                  onChange={handleChangeTab(`${item?.id}`)}
                >
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls={`${item?.id}bh-content`}
                    id={`${item?.id}bh-header`}
                  >
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems="center"
                      width="100%"
                    >
                      <Stack>
                        <Box sx={{ display: "flex" }} textAlign="left">
                          <Typography color={(theme) => theme.gradient[900]}>
                            ฝาก
                          </Typography>
                          <Typography sx={{ ml: 1 }} color={status.color}>
                            {numeral(item.amount).format("0,0.00")} ฿
                          </Typography>
                        </Box>
                        <Box>
                          <CreatedAt>
                            {moment(item.created_at)
                              .add("543", "years")
                              .format("DD/MM/YYYY HH:mm:ss")}
                          </CreatedAt>
                        </Box>
                      </Stack>
                      <Status
                        label={status.value}
                        color={"success"}
                        icon={
                          <Icon icon="material-symbols:check" height={20} />
                        }
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Description>
                      {item?.action_type === "auto"
                        ? "ฝากเงินอัตโนมัติ"
                        : "ฝากโดยแอดมิน"}
                      {item?.description && <Box>{item?.description}</Box>}
                    </Description>
                  </AccordionDetails>
                </Accordion>
              );
            })}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <FormControl variant="outlined" size="small">
                <InputLabel>Rows per page</InputLabel>
                <Select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  label="Rows per page"
                >
                  {[10, 25, 50].map((rows) => (
                    <MenuItem key={rows} value={rows}>
                      {rows}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Pagination
                count={Math.ceil(histories?.deposits.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Stack>
          </>
        ) : (
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            ไม่พบข้อมูล
          </Typography>
        )}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        {paginatedWithdraws?.length > 0 ? (
          <>
            {paginatedWithdraws?.map((item: any, key: any) => {
              const status = parseStatus(item.status);
              return (
                <Accordion
                  key={key}
                  sx={{ width: "100%" }}
                  expanded={expanded === `${item?.id}`}
                  onChange={handleChangeTab(`${item?.id}`)}
                >
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls={`${item?.id}bh-content`}
                    id={`${item?.id}bh-header`}
                  >
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems="center"
                      width="100%"
                    >
                      <Stack>
                        <Box sx={{ display: "flex" }} textAlign="left">
                          <Typography>ถอน</Typography>
                          <Typography sx={{ ml: 1 }} color={status.color}>
                            {numeral(item.amount).format("0,0.00")} ฿
                          </Typography>
                        </Box>
                        <Box>
                          <CreatedAt>
                            {moment(item.created_at)
                              .add("543", "years")
                              .format("DD/MM/YYYY HH:mm:ss")}
                          </CreatedAt>
                        </Box>
                      </Stack>
                      <Status
                        label={status.value}
                        color={"success"}
                        sx={{ backgroundColor: status.color }}
                        {...(item.status === "success" && {
                          icon: (
                            <Icon icon="material-symbols:check" height={20} />
                          ),
                        })}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Description>ไม่พบรายละเอียด</Description>
                  </AccordionDetails>
                </Accordion>
              );
            })}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <FormControl variant="outlined" size="small">
                <InputLabel>Rows per page</InputLabel>
                <Select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  label="Rows per page"
                >
                  {[10, 25, 50].map((rows) => (
                    <MenuItem key={rows} value={rows}>
                      {rows}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Pagination
                count={Math.ceil(histories?.withdraws.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Stack>
          </>
        ) : (
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            ไม่พบข้อมูล
          </Typography>
        )}
      </CustomTabPanel>
    </Box>
  );
}
