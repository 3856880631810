import SaveImageButton from "@/components/ui/SaveImageButton";
import { useAuthStore } from "@/stores/authStore";
import useBankDeposit from "@/hooks/fetchers/useBankDeposit";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { blue, green, grey, orange, purple } from "@mui/material/colors";
import Image from "next/image";
import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import { useTheme } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import http from "@/libs/http";
import { useQRCode } from "next-qrcode";
import { apiPath } from "@/configs/apiPath";
import { useSnackbar } from "notistack";
import { border } from "@mui/system";
import CountdownTimer from "@/components/ui/CountdownTimer";
import CopyButton from "@/components/ui/CopyButton";
import { useConfigStore } from "@/stores/configStore";
import moment from "moment";
import numeral from "numeral";

type Props = {};

type Inputs = {
  amount: number;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("กรุณากรอกจำนวนเงิน")
});

const generateQRCodeKbank = async ({
  amount
}: {
  amount: number;
}) => {
  try {
    const { data } = await http.post(apiPath.bank.qrCodeKbank, {
      "amount": `${amount}`,
    });

    return {
      status: true,
      data: data
    }
  } catch {
    return {
      status: false,
      data: null
    };
  }
};

const generateQRCodeVizplay = async ({
  amount
}: {
  amount: number;
}) => {
  try {
    const { data } = await http.post(apiPath.bank.qrCodeVizplay, {
      "amount": `${amount}`,
    });

    return {
      status: true,
      data: data
    }
  } catch {
    return {
      status: false,
      data: null
    };
  }
};

const Deposit = (props: Props) => {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { Image: QRImage } = useQRCode();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [isLoading, setLoading] = useState(false)
  const [deposit, setDeposit] = useState<any>(null)
  const { data: depositBanks } = useBankDeposit();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Inputs>({ resolver: yupResolver(schema) });
  const [isExpired, setExpired] = useState(false);
  const [provider, setProvider] = useState("QR");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { amount } = data;
    setLoading(true);

    try {
      if (amount <= 0) {
        setLoading(false);
        setError("amount", { message: 'ฝากเงินขั้นต่ำ 1 บาท' })
        return
      }

      const isKbank = depositBanks?.data?.find((bank) => bank.bank_code === "KBANK")
      const isVizplay = depositBanks?.data?.find((bank) => bank.bank_code === "VIZPAY")
      const compareDate = moment()
      const compareDateHour = compareDate.format("HH");
      const compareDateMinute = compareDate.format("mm");

      const qrCode = isVizplay
        ? (
          (parseInt(compareDateHour) >= 22 && parseInt(compareDateMinute) >= 30) ||
          (parseInt(compareDateHour) <= 2 && parseInt(compareDateMinute) <= 30)
        )
          ? await generateQRCodeKbank({ amount })
          : await generateQRCodeVizplay({ amount })
        : await generateQRCodeKbank({ amount })

      if (!qrCode.status) {
        setLoading(false);
        enqueueSnackbar("something went wrong", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        if (!qrCode.data.status) {
          setLoading(false);
          enqueueSnackbar(qrCode?.data?.msg || "", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          setLoading(false);
          setDeposit(qrCode.data.data)
        }
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar("something went wrong", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  if (!depositBanks?.data?.find((bank) => (bank.bank_code === "KBANK" || bank.bank_code === "VIZPAY") && bank)) {
    return (
      <Box>
        {/* <Divider>
          <Chip
            label="ฝากเงิน"
            color="primary"
            size="medium"
            style={{ fontSize: "18px", fontWeight: 700 }}
          />
        </Divider> */}
      </Box>
    )
  }

  return (
    <Box width={'100%'}>
      {/* <Divider>
        <Chip
          label="ฝากเงิน"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider> */}
      {
        deposit ? (
          <Box
            // bgcolor={theme.palette.background.default}
            // p={2}
            borderRadius={1}
          // mt={2}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={'column'}
            >

              <Box width={'100%'} display="flex" flexDirection={"column"} alignItems={"center"}>
                <SaveImageButton text={deposit?.qrcode || ''} />
              </Box>
              <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
                {
                  isExpired && (
                    <Box display="flex" flexDirection={"column"} alignItems={"center"}>
                      <Typography
                        variant="h5"
                        fontWeight={"900"}
                        sx={{
                          backgroundColor: '#e11d48',
                          padding: '0.5rem',
                          borderRadius: '0.25rem',
                          position: 'absolute',
                          marginTop: '108px',
                        }}
                      >
                        หมดอายุ
                      </Typography>
                    </Box>
                  )
                }
                <QRImage
                  text={deposit?.qrcode || ""}
                  options={{
                    type: "image/jpeg",
                    quality: 1,
                    errorCorrectionLevel: "M",
                    margin: 3,
                    scale: 4,
                    width: 256,
                  }}
                />
              </Box>
              <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
                {/* <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                  <Typography variant="h6" lineHeight={1.4} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                    ยอดโอนทั้งหมด
                  </Typography>
                </Box>
                <Typography variant="h3" fontWeight={"900"} lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }} mt={1}>
                  {`${deposit.request_amount || deposit.amount} บาท`}
                </Typography> */}

                <Stack justifyContent={"center"} alignItems={"center"}>
                  <Typography align="center" color={(theme) => theme.gradient[900]}>ยอดโอนทั้งหมด</Typography>
                  <Divider
                    sx={{
                      // mt: -1,
                      borderColor: theme.palette.primary.main,
                      borderRadius: "10px",
                      borderWidth: "1px",
                      width: "20px",
                    }}
                  />
                  <Typography
                    align="center"
                    fontSize="32px"
                    fontWeight={"bold"}
                    color={(theme) => theme.gradient[900] || theme.palette.primary.main}
                  >
                    {numeral(deposit.request_amount || deposit.amount).format("0,0.00")}
                  </Typography>
                </Stack>

                {/* <Typography variant="subtitle1" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }} mt={1}>
                  ทำรายการตามที่ สแกน QR (โอนรวมทศนิยม)
                </Typography>
                <Typography variant="subtitle1" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                  QR นี้ใช้แล้วห้ามใช้โอนซ้ำ
                </Typography> */}

                {
                  !isExpired && (
                    <Typography variant="subtitle1" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }} mt={1}>
                      <CountdownTimer
                        setExpired={setExpired}
                        time={new Date().getTime() + (5 * 60000)}
                      />
                    </Typography>
                  )
                }
              </Box>
            </Box>

            {/* <Box mt={2}>
              <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
                <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                  คำเตือน
                </AlertTitle>
                <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                  - กรุณาชำระเงินภายในเวลา 5 นาที
                </Typography>
                <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                  - QR code ใช้ได้เพียงครั้งเดียวเท่านั้น ห้ามใช้ซ้ำ หากสแกนซ้ำเข้ามา ทางเราไม่รับผิดชอบทุกกรณี
                </Typography>
              </Alert>
            </Box> */}
          </Box>
        ) : (
          <Box
            // bgcolor={theme.palette.background.default}
            // p={2}
            borderRadius={1}
            // mt={2}
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={'column'}
            >

              {
                depositBanks?.data?.find((bank) => (bank.bank_code === "TMN") && bank) && (
                  <Box
                    flex="1"
                    display={"flex"}
                    justifyContent={"start"}
                    alignItems={"center"}
                    width={'100%'}
                  >
                    <Grid item md={8} xs={24} width={'100%'}>
                      <Grid
                        container
                        style={{
                          borderRadius: 10,
                          overflow: "hidden",
                        }}
                        alignItems={"stretch"}
                        direction={"row"}
                        height={"100%"}
                        columns={2}
                      >
                        <Grid item xs={1} md={1} m={0} p={0}>
                          <Button
                            // startIcon={<Icon icon="lets-icons:up" />}
                            size="large"
                            variant="contained"
                            fullWidth
                            sx={{
                              background: (theme) =>
                                theme.gradient[500] ||
                                "linear-gradient(0deg, rgba(3,107,34,1) 0%, rgba(0,255,76,1) 100%)",
                              color: "white",
                              height: "100%",
                              fontWeight: 700,
                              borderRadius: 0,
                              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
                            }}
                            onClick={() => setProvider("QR")}
                          >
                            QR Payment
                          </Button>
                        </Grid>
                        <Grid item xs={1} md={1}>
                          <Button
                            // endIcon={<Icon icon="lets-icons:down" />}
                            size="large"
                            variant="contained"
                            fullWidth
                            sx={{
                              background: (theme) =>
                                theme.gradient[600] ||
                                `${orange["800"]} !important`,
                              color: "white",
                              height: "100%",
                              fontWeight: 700,
                              borderRadius: 0,
                              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
                            }}
                            onClick={() => setProvider("TMN")}
                          >
                            True Money
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )
              }

              {
                provider === 'QR' ? (
                  <>
                    {/* <Box
                      mt={depositBanks?.data?.find((bank) => (bank.bank_code === "TMN") && bank) ? 2 : 0}
                      flex="1"
                      display={"flex"}
                      justifyContent={"start"}
                      alignItems={"center"}
                    >
                      <Image
                        src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/qrstep2_th.png`}
                        width={isMobile ? 300 : 400}
                        height={isMobile ? 93.75 : 125}
                        alt={'qrstep'}
                      />
                    </Box> */}

                    <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
                      <Typography variant="subtitle2" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                        ระบบเติมเงินอัตโนมัติได้ไวได้เร็ว
                      </Typography>
                      <Typography variant="subtitle2" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                        งดฝากช่วงเวลา 00.30 - 01.00 น. ของทุกวัน
                      </Typography>
                    </Box>
                    <Box flexWrap={'wrap'} flex="1" display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={2} sx={{ width: '100%' }} rowGap={'8px'} columnGap={'8px'}>
                      {
                        [100, 300, 500, 1000, 3000, 5000, 10000, 30000].map(value => {
                          return (
                            <Button
                              sx={{ width: '23%' }}
                              key={value}
                              onClick={() => { setValue('amount', value) }}
                              variant="outlined"
                              color="primary"
                              size="small"
                            >
                              {numeral(value).format("0,0")}
                            </Button>
                          )
                        })
                      }
                    </Box>
                    <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
                      <FormControl fullWidth>
                        <Controller
                          name="amount"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              id="username"
                              // autoFocus
                              autoComplete="off"
                              inputProps={{
                                min: 0,
                                sx: {
                                  textAlign: 'center',
                                  fontSize: '1.25rem',
                                  fontWeight: 700,
                                  color: (theme: any) => theme.gradient[920],
                                  borderColor: (theme: any) => theme.gradient[920],
                                  borderStyle: "solid",
                                  borderWidth: 1,
                                  borderRadius: "16px",
                                }
                              }}
                              sx={{
                                ...(errors.amount && {
                                  borderColor: (theme) => theme.gradient[920] || "error.main",
                                  borderStyle: "solid",
                                  borderWidth: 1,
                                  borderRadius: "16px",
                                  "& .MuiInputLabel-root": {
                                    color: (theme) => theme.gradient[920] || "error.main",
                                  }
                                })
                              }}
                              value={value}
                              onBlur={onBlur}
                              onChange={(e) => {
                                const numericValue = e.target.value.replace(/\D/g, "");
                                onChange(numericValue);
                              }}
                              error={Boolean(errors.amount)}
                              placeholder="จำนวนเงิน"
                            />
                          )}
                        />
                        {errors.amount && (
                          <FormHelperText
                            sx={{
                              ...(errors.amount && {
                                borderColor: (theme) => theme.gradient[920] || "error.main",
                                color: (theme) => "error.main",
                                ml: 0
                              })
                            }}>
                            {`**${errors.amount.type === "typeError" ? 'กรุณากรอกจำนวนเงิน' : errors.amount.message}`}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                    <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        loading={isLoading}
                        sx={{
                          fontSize: '1.5rem',
                          fontWeight: 700,
                          color: '#fff',
                          background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                          height: "50px",
                          "&:hover": {
                            color: '#fff',
                            background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                          },
                        }}
                      >
                        เติมเงิน
                      </LoadingButton>
                    </Box>

                    {/* <Box mt={2}>
                      <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
                        <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                          คำเตือน
                        </AlertTitle>
                        <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                          - กรุณาชำระเงินภายในเวลา 5 นาที
                        </Typography>
                        <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                          - QR code ใช้ได้เพียงครั้งเดียวเท่านั้น ห้ามใช้ซ้ำ หากสแกนซ้ำเข้ามา ทางเราไม่รับผิดชอบทุกกรณี
                        </Typography>
                      </Alert>
                    </Box> */}
                  </>
                ) : (
                  <>
                    <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
                      <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                        <Typography variant="h6" lineHeight={1.4} align="center" sx={{ color: (theme) => theme.gradient[920] }} mr={1}>
                          กด "คัดลอก" เพื่อนำหมายเลขโทรศัพท์ไปทำการโอนเงิน
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={2} width={'100%'}>
                      <Stack>
                        <Box
                          bgcolor={orange["800"]}
                          p={2}
                          borderRadius={1}
                        >
                          <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Box
                              flex="1"
                              display={"flex"}
                              justifyContent={"start"}
                              alignItems={"center"}
                            >
                              <Image
                                src={`/assets/bank-logo/TMN.png`}
                                width={100}
                                height={100}
                                alt={"TMN"}
                                style={{ borderRadius: "100%" }}
                              />
                            </Box>
                            <Box display="flex" flexDirection={"column"} alignItems={"end"}>
                              {
                                config?.agent_name === 'simple888' && (
                                  <>
                                    <Typography variant="h6" lineHeight={1.4} sx={{ color: (theme) => theme.gradient[900] }}>
                                      {depositBanks?.data?.find((bank) => (bank.bank_code === "TMN") && bank)?.bank_account_name || ''}
                                    </Typography>
                                    <Typography variant="h6" lineHeight={1.2} sx={{ color: (theme) => theme.gradient[900] }}>
                                      {depositBanks?.data?.find((bank) => (bank.bank_code === "TMN") && bank)?.bank_account_no || ''}
                                    </Typography>
                                  </>
                                )
                              }
                              <CopyButton text={depositBanks?.data?.find((bank) => (bank.bank_code === "TMN") && bank)?.bank_account_no || ''} />
                            </Box>
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </>
                )
              }
            </Box>
          </Box>
        )
      }
    </Box>
  );
};

export default Deposit;
