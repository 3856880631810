import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

// type Game = {
//   category: string;
//   name: string;
//   match_name: string;
//   code: string;
//   status: boolean;
//   display: boolean;
//   image: string;
//   is_lobby: boolean;
//   games_lobby: any[];
// };

type UseGamesReturn = {
  data: any | null;
  isLoading: boolean;
  isError: any;
};

export default function useLobbyPG(): UseGamesReturn {
  const { data, error } = useSWR<any[]>(
    `/api/v2/users/lobbyPG`,
    fetcherAuth
  );
  return {
    data: data || null,
    isLoading: !error && !data,
    isError: error,
  };
}
