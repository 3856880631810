import { useAuthStore } from "@/stores/authStore";
import {
  Box, Chip,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { shallow } from "zustand/shallow";
import { useUserProfile } from "@/hooks";

type Props = {};

const UserProfile = (props: Props) => {
  const [user] = useAuthStore((state) => [state.user], shallow);
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    // isBonus,
    // handleOpenModal,
  } = useUserProfile();

  return (
    <Box pt={2} width={'100%'}>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            ธนาคาร:
          </Typography>
          <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
            fontSize: 18,
          }}>
            {user?.bank_name}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            เลขที่บัญชี:
          </Typography>
          <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
            fontSize: 18,
            // fontWeight: 700,
          }}>
            {user?.account_number}
            {/* {numeral(totalDeposits).format("0,0.00")} */}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            ชื่อบัญชี:
          </Typography>
          <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
            fontSize: 18,
          }}>
            {user?.fullname}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            เบอร์โทร:
          </Typography>
          <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
            fontSize: 18,
          }}>
            {user?.username}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={1}
      >
        <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            ยูสเซอร์:
          </Typography>
          <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
            fontSize: 18,
          }}>
            {user?.gameusername}
          </Typography>
        </Box>
      </Stack>

      {/* <Box mt={2}>
        <Alert severity="info" component={Box}>
          <AlertTitle>ขั้นตอนการถอนเครดิต</AlertTitle>
          1). โปรดระบุจำนวนเงินที่ต้องการถอนเครดิต โดยมีขั้นต่ำที่ <br />
          2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
        </Alert>
      </Box> */}
      {/* <Box mt={2}>
        <Alert severity="warning">
          <AlertTitle>คำเตือน</AlertTitle>
          ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น
        </Alert>
      </Box> */}
      {/* <Box p={2} borderRadius={1} mb={2}>
        <Box display="flex" flexDirection={"column"} alignItems={"center"}>
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
          </Box>
          <Typography
            variant="body1"
            lineHeight={1.5}
            mt={1}
          >
            {user?.bank_name}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              เลขที่บัญชี:{" "}
            </Typography>
            {user?.account_number}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              ชื่อ-นามสกุล:{" "}
            </Typography>
            {user?.fullname}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.username || '')}>
            <Typography component={"span"} color={blue[200]}>
              เบอร์โทร:{" "}
            </Typography>
            {user?.username}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.gameusername || '')}>
            <Typography component={"span"} color={blue[200]}>
              ยูสเซอร์:{" "}
            </Typography>
            {user?.gameusername}
          </Typography>
        </Box>
      </Box> */}

      {/* <Divider>
        <Chip
          label="โปรโมชั่น"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      <Box p={2} borderRadius={1} mt={2} mb={2}>
        <Box display="flex" flexDirection={"column"} alignItems={"center"}>
          <Typography sx={{ textAlign: 'center' }} variant="body1" lineHeight={0} onClick={() => { }}>
            <Typography component={"span"} color={blue[200]}>
              โปรโมชั่น:{" "}
            </Typography>
            {
              promotionHistory
                ? isPromotionHistoryLoading
                  ? "-"
                  : promotionHistory?.promotion.name
                : "-"
            }
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default UserProfile;
