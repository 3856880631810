import WithdrawForm from "@/sections/WithdrawForm";
import WithdrawFormAll from "@/sections/WithdrawFormAll";
import CopyButton from "@/components/ui/CopyButton";
import { useAuthStore } from "@/stores/authStore";
import { ContentCopy } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { blue, grey, purple } from "@mui/material/colors";
import Image from "next/image";
import React from "react";
import { shallow } from "zustand/shallow";
import { useConfigStore } from "@/stores/configStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";

type Props = {};

const Withdraw = (props: Props) => {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { data: promotion } = usePromotionHistory();

  return (
    <Box width={'100%'}>
      {/* <Typography variant="h6">เติมเครดิต</Typography> */}
      {/* <Divider>
        <Chip
          label="แจ้งถอนเครดิต"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider> */}
      {/* <Box mt={2}>
        <Alert
          variant="outlined"
          severity="success"
          color="warning"
          component={Box}
          sx={{
            border: (theme) => `1px solid ${theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main)}`,
            "& .MuiAlert-icon": {
              color: (theme) => theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main),
            },
            "& .MuiAlert-message": {
              color: (theme) => theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main),
              borderLeft: (theme) => `1px solid ${theme.name === 'preset14' ? "#ffe2b7" : (theme.gradient[911] ? theme.gradient[911] : theme.gradient[900] ?? theme.palette.success.main)}`,
              paddingLeft: 2,
            },
          }}
        >
          <AlertTitle
            sx={{
              fontWeight: 700,
              color: (theme) => theme.gradient[900]
            }}
          >
            ขั้นตอนการถอนเครดิต
          </AlertTitle >
          {
            (config?.is_withdraw_all || promotion) ? (
              <>
                1). จะทำการถอนเงินทั้งหมดออกจากกระเป๋า <br />
                2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
              </>
            ) : (
              <>
                1). จะทำการถอนเงินโดยระบุจำนวนออกจากกระเป๋า <br />
                2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
              </>
            )
          }
        </Alert>
      </Box> */}
      <Box borderRadius={1} mt={2} width={'100%'}>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
          mb={1}
        >
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Image
              src={`${user?.bank_logo || ""}`}
              width={96}
              height={96}
              alt={`${user?.bank_code} BANK`}
            />
          </Box>
        </Stack>

        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
          mb={1}
        >
          <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <Typography align="center" color={(theme) => theme.gradient[900]}>
              ธนาคาร:
            </Typography>
            <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
              fontSize: 18,
            }}>
              {user?.bank_name}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
          mb={1}
        >
          <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <Typography align="center" color={(theme) => theme.gradient[900]}>
              เลขที่บัญชี:
            </Typography>
            <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
              fontSize: 18,
              // fontWeight: 700,
            }}>
              {user?.account_number}
              {/* {numeral(totalDeposits).format("0,0.00")} */}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
          mb={1}
        >
          <Box width={'100%'} sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <Typography align="center" color={(theme) => theme.gradient[900]}>
              ชื่อบัญชี:
            </Typography>
            <Typography align="center" variant="h6" fontWeight={500} color="white" sx={{
              fontSize: 18,
            }}>
              {user?.fullname}
            </Typography>
          </Box>
        </Stack>

        <Divider/>

        <Box mt={1}>
          {
            (config?.is_withdraw_all || promotion)
              ? <WithdrawFormAll />
              : <WithdrawForm />
          }
        </Box>

        {/* <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          mb={4}
        >
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Image
              // src={`/assets/bank-logo/${user?.bank_code}.png`}
              src={`${user?.bank_logo || ""}`}
              width={100}
              height={100}
              alt={`${user?.bank_code} BANK`}
            />
          </Box>
          <Typography
            variant="button"
            // color={grey[500]}
            lineHeight={1.5}
            mt={1}
            sx={{ color: theme => theme.gradient[900] }}
          >
            {user?.bank_name}
          </Typography>
          <Typography variant="body1" lineHeight={0} sx={{ color: theme => theme.gradient[900] }}>
            <Typography
              component={"span"}
              color={(theme) => theme.gradient[910] || theme.palette.primary.main}
              fontWeight={600}
            >
              เลขที่บัญชี:{" "}
            </Typography>
            {user?.account_number}
          </Typography>
          <Typography variant="body1" lineHeight={0} sx={{ color: theme => theme.gradient[900] }}>
            <Typography
              component={"span"}
              color={(theme) => theme.gradient[910] || theme.palette.primary.main}
              fontWeight={600}
            >
              ชื่อบัญชี:{" "}
            </Typography>
            {user?.fullname}
          </Typography>
        </Box>
        <Box mt={2}>
          <Alert
            // sx={{ color: theme => theme.gradient[910] || theme.palette.warning.main }}
            variant="outlined"
            severity="warning"
            sx={{
              borderColor: theme => theme.gradient[910] || theme.gradient[912] || theme.palette.warning.main,
              color: theme => theme.gradient[910] || theme.gradient[912] || theme.palette.warning.main,
              "& .MuiAlert-icon": {
                color: (theme) => theme.gradient[910] || theme.gradient[912] || theme.palette.warning.main,
              },
            }}
            component={Box}
          >
            <AlertTitle sx={{ color: theme.gradient[910] || theme.gradient[912] }}>คำเตือน</AlertTitle>
            ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น
          </Alert>
        </Box>
        <Box mt={2}>
          {
            (config?.is_withdraw_all || promotion) 
              ? <WithdrawFormAll />
              : <WithdrawForm />
          }
        </Box> */}
      </Box>
    </Box>
  );
};

export default Withdraw;
