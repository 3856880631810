"use client";

import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from "@mui/material";
import * as React from "react";

import { ChatRounded, Home } from "@mui/icons-material";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Icon } from "@iconify/react";
import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAuthStore } from "@/stores/authStore";

export interface IAppProps { }

type BottomNavigationAction = {
  path: string;
  label: string;
  icon: string;
};

const bottomNavigationActions: BottomNavigationAction[] = [
  {
    path: "/",
    label: "หน้าหลัก",
    icon: 'solar:home-bold'
  },
  {
    path: "/",
    label: "กิจกรรม",
    icon: 'solar:gift-bold'
  },
  {
    path: "/",
    label: "เข้าสู่ระบบ",
    icon: 'solar:login-3-bold'
  },
  {
    path: "/",
    label: "สมัคร",
    icon: 'solar:user-plus-bold'
  },
  {
    path: "/",
    label: "ฝากเงิน",
    icon: 'solar:wallet-bold'
  },
  {
    path: "/",
    label: "ถอนเงิน",
    icon: 'solar:chat-round-money-bold'
  },
  {
    path: "LINE",
    label: "ติดต่อเรา",
    icon: 'solar:phone-calling-rounded-bold'
  },
];

export default function LayoutButtomNavigation(props: IAppProps) {
  const path = usePathname();
  const [user] = useAuthStore((state) => [state.user!], shallow);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  React.useEffect(() => {
    const index = bottomNavigationActions.findIndex(
      (action) => action.path === path
    );
    setActiveIndex(index);
  }, [path]);

  return !user && (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
        m: 0,
      }}
    >
      <BottomNavigation
        sx={{
          background: (theme) => theme.name === 'preset14'
            ? 'linear-gradient(180deg, #FC9ED3, #d566f0)'
            : theme.gradient[400],
        }}
        showLabels
        value={activeIndex}
        onChange={(event, newValue) => {
          setActiveIndex(newValue);
        }}
      >
        {bottomNavigationActions
          .filter(action => {
            const userList = ['หน้าหลัก', 'ฝากเงิน', 'ถอนเงิน', 'ติดต่อเรา']
            const guestList = ['หน้าหลัก', 'เข้าสู่ระบบ', 'สมัคร', 'ติดต่อเรา']

            return user
              ? userList.find((menu: any) => menu === action.label)
              : guestList.find((menu: any) => menu === action.label)
          })
          .map((action, index) => (
            <BottomNavigationAction
              component={Link}
              href={
                action.path == "LINE" ? config?.line_oa_url || "" : action.path
              }
              target={action.path == "LINE" ? "_blank" : "_self"}
              key={index}
              label={action.label}
              icon={
                <Icon
                  id="icon"
                  icon={action.icon || ''}
                  width={24}
                  height={24}
                />
              }
              sx={{
                // color: (theme) => theme.gradient[800],
                color: '#fff',
                '&.Mui-selected': {
                  color: '#fff',
                },
              }}
              onClick={(e) => {
                if (action.label === 'หน้าหลัก' || action.label === 'ติดต่อเรา') {
                  if(action.label === 'หน้าหลัก'){
                    window.scrollTo(0, 1);
                  }
                  return;
                } else {
                  e.preventDefault()

                  switch (action.label) {
                    case 'ฝากเงิน':
                      setOpenAppModal(true);
                      setData('deposit');
                      return;

                    case 'ถอนเงิน':
                      setOpenAppModal(true);
                      setData('withdraw');
                      return;

                    case 'กิจกรรม':
                      setOpenAppModal(true);
                      setData('promotion');
                      return;

                    case 'เข้าสู่ระบบ':
                      setOpenLoginModal(true);
                      return;


                    case 'สมัคร':
                      setOpenAppModal(true);
                      setData('signUp');
                      return;

                    default:
                      return;
                  }
                }
              }}
            />
          ))}
      </BottomNavigation>
    </Container>
  );
}
