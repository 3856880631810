"use client";

import useProvidersList from "@/hooks/useProvidersList";
import gamelist from "@/hooks/fetchers/useGameProvider";
import lobbyList from "@/hooks/fetchers/useLobbyPG";
import useLaunch from "@/hooks/useLaunch";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import {
  Box,
  Card,
  CardMedia, CardMediaProps, CircularProgress, Grid,
  Typography,
  Divider,
  Paper,
  useMediaQuery,
  TextField,
  InputAdornment,
  IconButton,
  Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { Login } from "@/sections";
import { Logo } from "../components/logo";
import GameMenu from "./GameMenu";
import DepositAndWithdrawButtonGroup from "./DepositAndWithdrawButtonGroup";
import MainMenu from "./MainMenu";
import { useState } from "react";
import Close from '@mui/icons-material/Close';
import { useUserProfile } from "@/hooks";
import SectionList from "@/components/carousel/SectionList";
import { useRouter } from "next/navigation";
import { Icon } from "@iconify/react";
import { useAuthStore } from "@/stores/authStore";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ItemImage = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
  borderRadius: "16px",
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
}));

export default function GamesListProvider(props: { provider: string }) {
  const route = useRouter();
  const [searchString, setSearchString] = useState('')
  const { theme, handleRightMenuClick, isLoggedIn, router, isActive, activePromotionPlatform, isGameLaunchLoading, handleProviderClick } = useProvidersList();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { launchGame, loading } = useLaunch();
  const { data: gameList, isLoading: isGameListLoading } = lobbyList();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const {
    currentBalance,
    promotionHistory,
    handleRemovePro
  } = useUserProfile();

  // if (!isLoggedIn) {
  //   return (
  //     <Paper elevation={1}>
  //       <Box p={2} mt={3}>
  //         <Box
  //           mb={3}
  //           sx={{
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center'
  //           }}
  //         >
  //           <Logo
  //             src={config?.logo || ""}
  //             height={256}
  //           />
  //         </Box>
  //         <Box mb={3}>
  //           <Divider>
  //             <Typography variant="h5" align="center">
  //               เข้าสู่ระบบ
  //             </Typography>
  //           </Divider>
  //         </Box>
  //         <Box mt={2}>
  //           <Login />
  //         </Box>
  //       </Box>
  //     </Paper>
  //   )
  // }

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>

          {/* {
            isLoggedIn && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ pt: 3, pb: 0 }}
                >
                  <MainMenu />
                </Grid>
                {
                  isMobileDevice && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ pt: 3, pb: 0 }}
                    >
                      <DepositAndWithdrawButtonGroup />
                    </Grid>
                  )
                }
              </>
            )
          } */}

          {
            promotionHistory && (
              <Grid item xs={12} md={12} mt={2}>
                <Button
                  // endIcon={<Icon icon="lets-icons:down" />}
                  size="small"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: (theme) =>
                      theme.gradient[600] ||
                      "linear-gradient(0deg, #292838 0%, #45435e 100%)",
                    color: "white",
                    height: "100%",
                    fontSize: 18,
                    fontWeight: 700,
                    borderRadius: 1,
                    boxShadow: 'none',
                    justifyContent: 'space-between'
                    // textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
                  }}
                  onClick={() => currentBalance <= 5 ? handleRemovePro() : {}}
                // disabled={currentBalance > 5}
                >
                  โปรโมชั่น: {promotionHistory.promotion.name}
                  <Typography variant="h6" sx={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}>
                    {currentBalance <= 5 ? 'ออกจากโปร' : `${currentBalance}/${promotionHistory.withdraw_min.slice(0, -3)}`}
                  </Typography>
                </Button>
              </Grid>
            )
          }

          <Grid item xs={12} sx={{ pt: 1.5, pb: 0 }}>
            {/* <Typography variant="h6">
              {props.provider}
            </Typography>
            <Box
              mt={-0.5}
              borderRadius={8}
              width={"6px"}
              height={"4px"}
              bgcolor={theme.palette.primary.main}
            /> */}
          </Grid>

          <Grid item xs={12} md={12} mb={2} mt={1}>
            <Button
              startIcon={<Icon icon="solar:magnifer-bold" />}
              size="small"
              variant="contained"
              fullWidth
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                height: "100%",
                fontSize: 18,
                fontWeight: 500,
                borderRadius: 1,
                boxShadow: 'none',
                backgroundColor: "transparent",
                border: "1.5px solid rgba(255, 255, 255, 0.23)",
                justifyContent: 'start',
                pl: 2,
                py: 1,
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => isLoggedIn ? route.push('/lobbies/PG') : handleRightMenuClick()}
            >
              ค้นหา
            </Button>
          </Grid>

          {
            gameList?.lobby_list.map((section: any, index: number) => {
              let customSection = section
              if (customSection.type === 'provider') {
                const customList = customSection.list.map((data: any) => {
                  switch (data.platform) {
                    case 'PT':
                      return {
                        ...data,
                        onClick: () => { handleProviderClick('16190', 'PT', 'casino', 'PT', true, "") }
                      }

                    case 'SA':
                      return {
                        ...data,
                        onClick: () => { handleProviderClick('25853', 'SA', 'casino', 'SA', true, "") }
                      }

                    case 'HOTROAD':
                      return {
                        ...data,
                        onClick: () => { handleProviderClick('16310', 'HOTROAD', 'casino', 'HOTROAD', true, "") }
                      }

                    case 'PPL':
                      return {
                        ...data,
                        onClick: () => { handleProviderClick('15067', 'PPL', 'casino', 'PPL', true, "") }
                      }

                    case 'AE':
                      return {
                        ...data,
                        onClick: () => { handleProviderClick('9624', 'AE', 'casino', 'AE', true, "") }
                      }

                    case 'E1SPORT':
                      return {
                        ...data,
                        onClick: () => { handleProviderClick('15723', 'E1SPORT', 'casino', 'E1SPORT', true, "") }
                      }

                    default:
                      return {
                        ...data
                      }
                  }
                })

                customSection = { ...customSection, list: customList }
              }

              return (
                <SectionList
                  key={section.label}
                  section={customSection}
                  router={router}
                  isLoggedIn={isLoggedIn}
                  isActive={isActive}
                  activePromotionPlatform={activePromotionPlatform}
                  handleRightMenuClick={handleRightMenuClick}
                  launchGame={launchGame}
                  isGameLaunchLoading={false}
                  setCategory={() => { }}
                />
              )
            })
          }

        </Grid>
      </Box>
    )
  }
}
