import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useAuthModalStore } from "@/stores/authModalStore";

export default function DepositAndWithdrawButtonGroup() {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const handleOpenModal = (key: string) => {
    setOpenAppModal(true);
    setData(key);
  };

  return (
    <Grid item md={8} xs={24}>
      <Grid
        container
        style={{
          borderRadius: 10,
          overflow: "hidden",
        }}
        alignItems={"stretch"}
        direction={"row"}
        height={"100%"}
        columns={isLoggedIn ? 3 : 2}
        spacing={isLoggedIn ? 1 : 2}
      >
        {
          isLoggedIn && (
            <Grid item xs={1} md={1} m={0} p={0}>
              <Button
                // startIcon={<Icon icon="lets-icons:up" />}
                size="small"
                variant="contained"
                fullWidth
                sx={{
                  background: (theme) =>
                    theme.gradient[500] ||
                    "linear-gradient(0deg, #2f2f2f 0%, #4f4f4f 100%)",
                  color: "white",
                  height: "100%",
                  fontSize: 18,
                  fontWeight: 700,
                  borderRadius: 1,
                  boxShadow: 'none',
                  // backgroundColor: "transparent",
                  // backgroundImage: `url(${'https://www.ufakey777.bet/build/web/img/btn-deposit-bg.png'})`,
                  // backgroundSize: "contain",
                  // textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
                }}
                onClick={() => isLoggedIn ? handleOpenModal("promotion") : setOpenLoginModal(true)}
              >
                {'กิจกรรม'}
              </Button>
            </Grid>
          )
        }
        <Grid item xs={1} md={1} m={0} p={0}>
          <Button
            // startIcon={<Icon icon="lets-icons:up" />}
            size="small"
            variant="contained"
            fullWidth
            sx={{
              background: (theme) =>
                theme.gradient[500] ||
                "linear-gradient(0deg, rgba(3,107,34,1) 0%, rgba(0,255,76,1) 100%)",
              color: "white",
              height: "100%",
              fontSize: 18,
              fontWeight: 700,
              borderRadius: 1,
              boxShadow: 'none',
              // backgroundColor: "transparent",
              // backgroundImage: `url(${'https://www.ufakey777.bet/build/web/img/btn-deposit-bg.png'})`,
              // backgroundSize: "contain",
              // textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
            }}
            onClick={() => isLoggedIn ? handleOpenModal("deposit") : setOpenLoginModal(true)}
          >
            {isLoggedIn ? 'เติมเงิน' : 'เข้าสู่ระบบ'}
          </Button>
        </Grid>
        <Grid item xs={1} md={1}>
          <Button
            // endIcon={<Icon icon="lets-icons:down" />}
            size="small"
            variant="contained"
            fullWidth
            sx={{
              background: (theme) =>
                theme.gradient[600] ||
                "linear-gradient(330deg, rgba(133,6,6,1) 0%, rgba(193,0,0,1) 40%, rgba(255,36,36,1) 100%)",
              color: "white",
              height: "100%",
              fontSize: 18,
              fontWeight: 700,
              borderRadius: 1,
              boxShadow: 'none'
              // textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
            }}
            onClick={() => isLoggedIn ? handleOpenModal("withdraw") : handleOpenModal('signUp')}
          >
            {isLoggedIn ? 'ถอนเงิน' : 'สมัครสมาชิก'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
