import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useAuthModalStore } from "@/stores/authModalStore";
import numeral from "numeral";

export default function InformationGroup() {
  const theme = useTheme();
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const handleOpenModal = (key: string) => {
    setOpenAppModal(true);
    setData(key);
  };

  return (
    <Grid item md={8} xs={24}>
      <Grid
        container
        style={{
          borderRadius: 10,
          overflow: "hidden",
        }}
        alignItems={"stretch"}
        direction={"row"}
        height={"100%"}
        columns={4}
        spacing={1}
      >
        <Grid item xs={1} md={1} m={0} p={0} sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.23)' }}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography variant="caption" align="center" color={(theme) => 'rgba(255, 255, 255, 0.5)'}>ฝากขั้นต่ำ</Typography>
            <Divider
              sx={{
                borderColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: "10px",
                borderWidth: "1px",
                width: "20px",
                mb: 1
              }}
            />
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              color={(theme) => theme.gradient[900] || theme.palette.primary.main}
            >
              {`${numeral(1).format("0,0")} THB`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} md={1} m={0} p={0} sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.23)' }}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography variant="caption" align="center" color={(theme) => 'rgba(255, 255, 255, 0.5)'}>ถอนขั้นต่ำ</Typography>
            <Divider
              sx={{
                borderColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: "10px",
                borderWidth: "1px",
                width: "20px",
                mb: 1
              }}
            />
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              color={(theme) => theme.gradient[900] || theme.palette.primary.main}
            >
              {`${numeral(50).format("0,0")} THB`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} md={1} m={0} p={0} sx={{ borderRight: '1px solid rgba(255, 255, 255, 0.23)' }}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography variant="caption" align="center" color={(theme) => 'rgba(255, 255, 255, 0.5)'}>ฝากออโต้</Typography>
            <Divider
              sx={{
                borderColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: "10px",
                borderWidth: "1px",
                width: "20px",
                mb: 1
              }}
            />
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              color={(theme) => theme.gradient[900] || theme.palette.primary.main}
            >
              {`${numeral(3).format("0,0")} วินาที`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} md={1} m={0} p={0}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography variant="caption" align="center" color={(theme) => 'rgba(255, 255, 255, 0.5)'}>ถอนจำกัด</Typography>
            <Divider
              sx={{
                borderColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: "10px",
                borderWidth: "1px",
                width: "20px",
                mb: 1
              }}
            />
            <Typography
              variant="body1"
              align="center"
              fontWeight={"bold"}
              color={(theme) => theme.gradient[900] || theme.palette.primary.main}
            >
              {`ไม่จำกัด`}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}
