"use client";

import React from "react";
import moment from "moment";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import QRCode from 'qrcode'
import { CopyToClipboard } from "react-copy-to-clipboard";

type Props = {
    text: string;
};

const SaveImageButton = (props: Props) => {
    const saveImage = async () => {
        const now = moment().format("x");
        const imageURL = await QRCode.toDataURL(props.text)
        const a = document.createElement("a");
        a.href = imageURL
        a.download = `qrcode_${now}.png`;
        a.click();
    }

    return (
        <CopyToClipboard text={props.text}>
            <Button
                startIcon={<Download />}
                onClick={saveImage}
                variant="outlined"
                color="primary"
                size="small"
                sx={{  fontWeight: 700, color: (theme: any) => theme.gradient[920], width: '256px' }}
            >
                คลิกเพื่อดาวน์โหลด
            </Button>
        </CopyToClipboard>
    );
};

export default SaveImageButton;
